import React from 'react'



class OurService extends React.Component {


  render() {

    return (
      <div className='cryptoex padremove'>
      <section className="icon secureof pt-100">
        <div className="container">
          <h2 className="main-heading">Our <span className='bluecolor'>Diverse NFT Marketplace</span> Development Services</h2>
          <p className="sub-heading-bold">With advanced-level technology, we offer various NFT Marketplace Development services for our clients.</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-development-w.png" alt="NFT Development " />
                NFT Development 
                  </div>
                <p className="pharagraph">We create valuable NFTs in various categories like art, sports, music, etc based on the client's requirements. Our services can ensure a guaranteed output since experts from our company have extensive knowledge of NFT development tools.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-smart-contract-development-w.png" alt='NFT Smart Contract Development' />
                NFT Smart Contract Development
                </div>
                <p className="pharagraph">We understand the importance of a reliable and bug-free smart contract during NFT development. The NFT Smart contracts we develop undergo separate design evaluation, internal audits, and optimization strategies. </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-marketplace-app-development-w.png" alt='NFT Marketplace App Development' />
                NFT Marketplace App Development
                </div>
                <p className="pharagraph">For enhanced user engagement, providing robust NFT Marketplace apps should be the way to go. On account of that, we offer advanced NFT Marketplace apps on powerful blockchains by incorporating the best-upgraded tools. </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-websites-w.png" alt='NFT Website Development' />
                NFT Website Development
                </div>
                <p className="pharagraph">Our experts design and develop high-grade NFT Marketplace websites for improved trading experience among users. The NFT Marketplace websites we create have got everything covered including high-end security in all aspects.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv hovblue'>
                <div className='head3'>
                <a href='https://www.coinsclone.com/nft-art-marketplace-development/'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-art-marketplace-development-w.png" alt='NFT Art Marketplace Development ' />
                NFT Art Marketplace Development 
                </a>
                </div>
                <p className="pharagraph">Our NFT Art Marketplace Platform is a major boost to digital content creators. These platforms allow artists to convert their unique artwork into Non-fungible tokens and claim ownership by storing it on a blockchain.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv hovblue'>
                <div className='head3'>
                  <a href='https://www.coinsclone.com/nft-music-marketplace-development/'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-music-marketplace-development-w.png" alt='NFT Music Marketplace Development' />
                NFT Music Marketplace Development
                </a>
                </div>
                <p className="pharagraph">As experts in NFT projects, we help entrepreneurs build their own NFT Music Marketplace platforms. Rare and unique music digital collectibles can be converted into NFTs and traded on these platforms.</p>
              </div>
            </div>
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2 m-auto d-table">Free Trial</a>
          </div>
      </section>
      </div>
    )
  }
}

export default OurService